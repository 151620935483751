import React from "react";
import {
  AdjustmentsIcon,
  BeakerIcon,
  CogIcon,
  CurrencyEuroIcon,
  CurrencyDollarIcon,
  PresentationChartBarIcon,
  TrendingUpIcon,
  ChartBarIcon
} from "@heroicons/react/outline";


function App() {

  const getBox = (Icon, title, description, link) =>
    <div
      className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
      <div>
        <Icon
          className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </div>
      <div className="mt-2">
        <h3 className="text-lg font-medium">
          <a target="_blank" href={link} className="focus:outline-none text-am-500">
            <span className="absolute inset-0" aria-hidden="true"/>
            {title}
          </a>
        </h3>
        <p className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      </div>
      <span
        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"/>
        </svg>
      </span>
    </div>

  return (
    <>
      <div className="m-4 flex justify-center">
        <img
          width="160px"
          src={process.env.PUBLIC_URL + "/ammagamma-payoff.png"}
          alt="LOGO"
        />
      </div>
      <div className="text-center mb-4">
        <h3 className="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-2xl">Demo Webapp</h3>
      </div>

      <div className="m-auto md:max-w-4xl">
        <div
          className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
          {getBox(
            AdjustmentsIcon,
            'Anagram',
            'Production scheduler interactive platform',
            'https://demo.anagram.ammagamma.com'
          )}
          {getBox(
            CogIcon,
            'Aware',
            'Demand forecasting & inventory optimization platform',
            'https://demo-aware.cloud.ammagamma.com'
          )}
          {getBox(
            ChartBarIcon,
            'Ahead',
            'Demand forecasting & model performance analysis platform',
            'https://demo-ahead.cloud.ammagamma.com'
          )}
          {getBox(
            CurrencyDollarIcon,
            'aForex',
            'Foreign Exchange market interactive dashboard',
            'https://demo-forex.cloud.ammagamma.com'
          )}
          {getBox(
            PresentationChartBarIcon,
            'Template App',
            'Ammagamma dashboard skeleton and UI toolkit',
            'https://atemplateapp.cloud.ammagamma.com'
          )}
          {getBox(
            CurrencyEuroIcon,
            'Credit Recovery',
            'Interactive dashboard for debt collection management',
            'https://demo-credit.cloud.ammagamma.com'
          )}
          {getBox(
            TrendingUpIcon,
            'Demand Planning',
            'Forecasting algorithms for production planning',
            'https://demo-demand-planning.cloud.ammagamma.com'
          )}
          {getBox(
            BeakerIcon,
            'Wastewater Treatment',
            'Monitoring & analytics of wastewater treatment plants',
            'https://demo-wastewater.cloud.ammagamma.com'
          )}
        </div>
      </div>
    </>
  );
}

export default App;
